import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './libs/countUp.lib.js';
import './libs/topojson.lib.min.js';
import './libs/datepicker.lib.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/map.component.js';
import './components/lineTitles.component.js';
import Swiper, { EffectFade, Autoplay, Navigation } from 'swiper';
import { CountUp } from 'countup.js';
import Parallax from 'parallax-js';
import Typed from 'typed.js';
import SimpleBar from 'simplebar';
import scrollLock from 'scroll-lock';

/*import { Fancybox } from '@fancyapps/ui';*/


function scrollToCenter(selector) {
  const el = $(selector);

  $("html, body").animate(
      {
        scrollTop: el.offset().top - 220,
      },
      400
  );
}

if (location.hash) {
  const scrollSection = document.querySelector(location.hash);
  if (scrollSection) {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 1);

    setTimeout(() => {
      scrollToCenter(location.hash);
    }, 500);
  }
}



if (document.querySelector('#drop_country')) {
  const countryTrigger = document.querySelector('#drop_country');
  const countryBtn = document.querySelector('.header__label');
  const menu = document.querySelector('.header__navbar-box');

  menu.addEventListener('click', (e) => {
    if (e.target !== countryTrigger && e.target !== countryBtn) {
      countryTrigger.checked = false;
    }
  });
}



if (document.querySelector('.js-typed')) {
  new Typed('.js-typed', {
    strings: ['<p>Others Promise.^1000 <span >We Deliver.</span></p>'],
    typeSpeed: 50,
    onComplete: () => {
      const element = document.querySelector('.hero__subtitle span');
      element.classList.add('is-active');
    },
  });
}

if (document.querySelector('.text-typed-animation')) {
  const textTypedAnimationsArr = document.querySelectorAll('.text-typed-animation');

  const typingSpeed = 120;
  const animationDelay = 1000;
  const typeDelay = 1000;

  textTypedAnimationsArr.forEach(textTypedAnimation => {
    const firstText = textTypedAnimation.querySelector('.text-typed-first');
    const secondText = textTypedAnimation.querySelector('.text-typed-second');

    textTypedAnimation.style.opacity = 1;
    secondText.style.display = 'none';

    firstText.classList.add('text-cursor');
    secondText.classList.add('text-cursor');

    function eraseText(element, callback) {
      element.classList.add('typing');
      const textArray = element.textContent.split('');
      let index = textArray.length - 1;

      const interval = setInterval(() => {
        if (index >= 0) {
          textArray.pop();
          element.textContent = textArray.join('');
          index--;
        } else {
          clearInterval(interval);
          element.classList.add('after-opacity');

          setTimeout(function () {
            element.classList.remove('typing');
            element.style.opacity = 0;
          }, 1200)
          if (callback) callback();
        }
      }, typingSpeed);
    }

    function typeText(element) {
      element.classList.add('typing');
      const textArray = element.textContent.split('');
      element.textContent = '';
      let index = 0;

      element.style.display = 'inline';
      element.style.visibility = 'visible';

      if(element.classList.contains('text-typed-second')) {
        element.classList.add('after-opacity');
        setTimeout(function () {
          const interval = setInterval(() => {
            element.classList.remove('after-opacity');
            if (index < textArray.length) {
              element.textContent += textArray[index];
              index++;
            } else {
              clearInterval(interval);
              element.classList.remove('typing');
            }
          }, typingSpeed);
        }, animationDelay / 1.5)
      } else {
        const interval = setInterval(() => {
          if (index < textArray.length) {
            element.textContent += textArray[index];
            index++;
          } else {
            clearInterval(interval);
            element.classList.remove('typing');
          }
        }, typingSpeed);
      }
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            eraseText(firstText, () => {

              setTimeout(() => {
                typeText(secondText);
              }, typeDelay);
            });
          }, animationDelay);
          observer.unobserve(textTypedAnimation);
        }
      });
    }, {
      threshold: 0.5
    });

    observer.observe(textTypedAnimation);
  });
}

if (
  document.querySelector('.hero__img--first') &&
  document.querySelector('.hero__img--sec')
) {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();

  let imagesArr = ['.hero__img--first', '.hero__img--sec'];
  if (currentDayOfWeek === 0) {
    imagesArr.reverse();
  }
  localStorage.setItem('hero_images', JSON.stringify(imagesArr));
  const heroImages = JSON.parse(localStorage.getItem('hero_images'));

  const imgFirst = document.querySelector(heroImages[0]);
  const imgSecond = document.querySelector(heroImages[1]);
  const heroImagesArr = [imgFirst, imgSecond];

  heroImagesArr[+(currentDayOfWeek % 2 === 0)].classList.add('active');
}

const paralaxContainer = document.querySelectorAll('.parallax');
paralaxContainer.forEach((elem) => {
  let parallaxInstance = new Parallax(elem);
  parallaxInstance.enable();
});

function nameCut() {
  const upload = document.querySelector('.footer__upload');
  const uploadLabel = document.querySelector('.footer__upload-text');

  if (upload && uploadLabel) {
    upload.addEventListener('change', function () {
      let dots;
      const arr = this.files[0].name.split('.');
      arr[0].length > 6 ? (dots = '...') : (dots = '.');
      const name = arr[0].substring(0, 10) + dots + arr[1];
      uploadLabel.innerHTML = name;
    });
  }
}

nameCut();

// burger-menu
const burgerMenu = (menuSelector, burgerSelector) => {
  const menuElem = document.querySelector(menuSelector),
    layoutElem = document.querySelector('.header__layout'),
    burgerElem = document.querySelector(burgerSelector);

  const removeClass = () => {
    menuElem.classList.remove('active');
    scrollLock.enablePageScroll();
  };
  removeClass();

  menuElem.querySelectorAll('a').forEach((i) => {
    i.addEventListener('click', (e) => {
      if (
        menuElem.classList.contains('active') &&
        !i.classList.contains('open') &&
        e.target != document.querySelector('.header__link--btn span')
      ) {
        removeClass();
      }
    });
  });

  burgerElem.addEventListener('click', () => {
    if (menuElem.classList.contains('active')) {
      removeClass();
    } else {
      menuElem.classList.add('active');
      var method = 'margin';
      scrollLock.setFillGapMethod(method);
      scrollLock.disablePageScroll();

      if (document.querySelector('.header__simplebar') && innerWidth < 1024) {
        scrollLock.addScrollableSelector('.simplebar-content-wrapper');
      }

      scrollLock.addFillGapSelector('.header__navbar-box');
    }
  });

  window.addEventListener('resize', () => {
    if (innerWidth > 1023) {
      removeClass();
    }
  });

  layoutElem.addEventListener('click', () => {
    removeClass();
  });
};

// sticky

function stickyMenu() {
  const headerElem = document.querySelector('.header');

  if (window.pageYOffset >= 50) {
    headerElem.classList.add('sticky');
  } else {
    headerElem.classList.remove('sticky');
  }
}

if (document.querySelector('.header')) {
  window.addEventListener('scroll', stickyMenu);
  stickyMenu();
}
// end sticky

function uploadBtns() {
  const footer = document.querySelector('.footer');
  const btnBox = document.querySelector('.btn-box');
  const btns = document.querySelectorAll('.btn-box__btn');
  const sections = document.querySelectorAll('section');
  window.addEventListener('scroll', () => {
    btns.forEach((btn) => {
      if(sections.length > 1) {
        if (window.scrollY >= 100) {
          btn.classList.add('is-hide');
        } else {
          btn.classList.remove('is-hide');
          btn.classList.remove('is-hide');
        }
      }

      if (footer.offsetHeight > footer.getBoundingClientRect().top + (footer.offsetHeight / 2)) {
        btnBox.classList.add('is-hide');
      } else {
        btnBox.classList.remove('is-hide');
      }
    });
  });
}

uploadBtns();

if (document.querySelector('.header')) {
  burgerMenu('.header', '.header__burger');
}

// end burger-menu

// choices
const choicesArr = document.querySelectorAll('.js-choices');
if (choicesArr) {
  choicesArr.forEach((select) => {
    new Choices(select, {
      allowHTML: true,
      searchEnabled: false,
      placeholder: true,
      placeholderValue: null,
      searchPlaceholderValue: null,
      shouldSort: false,
    });
  });
}

if (document.querySelector('.choices')) {
  const choicesWrappers = document.querySelectorAll('.choices');
  choicesWrappers.forEach((choiceWrap) => {
    choiceWrap.addEventListener('click', () => {
      if (!choiceWrap.querySelector('.custom-simplebar-wrapper')) {
        const selectSimplebar = choiceWrap.querySelector(
          '.choices__list--dropdown .choices__list'
        );
        const div = document.createElement('div');
        div.classList.add('custom-simplebar-wrapper');
        const content = selectSimplebar.innerHTML;
        selectSimplebar.innerHTML = '';
        div.setAttribute('data-simplebar', '');
        div.innerHTML = content;
        selectSimplebar.appendChild(div);
      }
    });
  });
}
// end choices

if (document.querySelector('.looking--main .looking__slider')) {
  const swiper = new Swiper('.looking--main .looking__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.looking--main .slider-nav__btn--prev',
      nextEl: '.looking--main .slider-nav__btn--next',
    },
    breakpoints: {
      658: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });
}

if (document.querySelector('.blogs__slider')) {
  const swiper = new Swiper('.blogs__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.blogs .slider-nav__btn--prev',
      nextEl: '.blogs .slider-nav__btn--next',
    },
    breakpoints: {
      658: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });
}

if (document.querySelector('.benefits__slider')) {
  const swiper = new Swiper('.benefits__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.benefits .slider-nav__btn--prev',
      nextEl: '.benefits .slider-nav__btn--next',
    },
    breakpoints: {
      658: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });
}

// countUp
let countUpArr = new Array();

function CountUpFunction(id, finalNum, option = {}) {
  let defaultOptions = {
    separator: ',',
    enableScrollSpy: true,
    scrollSpyOnce: true,
  };

  let mergeOptions = { ...defaultOptions, ...option };
  countUpArr.push(new CountUp(id, finalNum, mergeOptions));
}

function counter(elements) {
  elements.forEach((counter, i) => {
    const value = counter.getAttribute('data-count');
    CountUpFunction(counter, value, { suffix: '' });
  });
}

if (document.querySelector('.specialist--country')) {
  const counters = document.querySelectorAll('.specialist__num');
  counter(counters);
}

if (document.querySelector('.specialist__slider')) {
  const slider = document.querySelector('.specialist__slider');
  const allSlides = slider.querySelectorAll(
    '.specialist__slider .swiper-slide'
  );
  let visibleSlidesArr = [];

  const addCounter = () => {
    allSlides.forEach((slide) => {
      if (slide.classList.contains('counted')) {
        slide.classList.remove('counted');
      }
      if (
        slide.classList.contains('swiper-slide-visible') &&
        !slide.classList.contains('counted')
      ) {
        const counterItem = slide.querySelector('.specialist__num');
        slide.classList.add('counted');
        visibleSlidesArr = [...visibleSlidesArr, counterItem];
      }
    });
    counter(visibleSlidesArr);
  };

  const counterSlider = new Swiper('.specialist__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    watchSlidesProgress: true,
    on: {
      resize: () => {
        addCounter();
      },
      init: () => {
        addCounter();
      },
      slideChange: () => {
        visibleSlidesArr = [];
        let allSlides = document.querySelectorAll(
          '.specialist__slider .swiper-slide'
        );

        allSlides.forEach((slide) => {
          if (
            slide.classList.contains('counted') &&
            !slide.classList.contains('swiper-slide-visible')
          ) {
            slide.classList.remove('counted');
          }
        });

        let visibleSlides = document.querySelectorAll(
          '.specialist__slider .swiper-slide-visible'
        );
        visibleSlides.forEach((slide) => {
          if (!slide.classList.contains('counted')) {
            const counterItem = slide.querySelector('.specialist__num');
            slide.classList.add('counted');
            visibleSlidesArr = [...visibleSlidesArr, counterItem];
          }
        });

        counter(visibleSlidesArr);
      },
    },

    navigation: {
      prevEl: '.specialist .slider-nav__btn--prev',
      nextEl: '.specialist .slider-nav__btn--next',
    },
    breakpoints: {
      640: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      900: {
        spaceBetween: 16,
        slidesPerView: 3,
      },
      1024: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
      1300: {
        spaceBetween: 74,
        slidesPerView: 5,
      },
    },
  });

  counterSlider.autoplay.stop();

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry, i) => {
        if (entry.isIntersecting && !slider.classList.contains('inited')) {
          slider.classList.add('inited');
          counterSlider.autoplay.start();
        }
      });
    },
    {
      threshold: 0,
      rootMargin: '0% 0% -10% 0%',
    }
  );

  observer.observe(slider);
}

if (document.querySelector('.looking--dark .looking__slider')) {
  new Swiper('.looking--dark .looking__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.looking--dark .slider-nav__btn--prev',
      nextEl: '.looking--dark .slider-nav__btn--next',
    },
    breakpoints: {
      658: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });
}
if (document.querySelector('.looking--country-dark .looking__slider')) {
  new Swiper('.looking--country-dark .looking__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.looking--country-dark .slider-nav__btn--prev',
      nextEl: '.looking--country-dark .slider-nav__btn--next',
    },
    breakpoints: {
      658: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });
}
if (document.querySelector('.looking--country-main .looking__slider')) {
  new Swiper('.looking--country-main .looking__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.looking--country-main .slider-nav__btn--prev',
      nextEl: '.looking--country-main .slider-nav__btn--next',
    },
    breakpoints: {
      658: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });
}

if (document.querySelector('.hero__slider')) {
  new Swiper('.hero__slider', {
    modules: [Navigation, Autoplay],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.hero .slider-nav__btn--prev',
      nextEl: '.hero .slider-nav__btn--next',
    },
  });
}

if (document.querySelector('.testimonials__slider')) {
  new Swiper('.testimonials__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.testimonials .slider-nav__btn--prev',
      nextEl: '.testimonials .slider-nav__btn--next',
    },
  });
}

if (document.querySelector('.clients__slider')) {
  const slides = document.querySelectorAll('.clients__slider .swiper-slide');
  const wrapper = document.querySelector('.clients__slider .swiper-wrapper');

  new Swiper('.clients__slider', {
    modules: [Autoplay],
    loop: window.innerWidth > 1000 ? true : false,
    slidesPerView: 3,
    centeredSlides: true,
    spaceBetween: 0,
    initialSlide: 1,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      768: {
        initialSlide: 1,
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
        initialSlide: 2,
      },
    },
  });
}

function resourcesAccardion(wrapperSelector, contentTrigger, contentSelector) {
  const wrappers = document.querySelectorAll(wrapperSelector);

  wrappers.forEach((wrap) => {
    const trigger = wrap.querySelector(contentTrigger);
    const content = wrap.querySelector(contentSelector);
    trigger.addEventListener('click', () => {
      let maxHeight = content.querySelector('div').clientHeight + 'px';
      if (trigger.classList.contains('open')) {
        trigger.classList.remove('open');
        content.classList.remove('open');
        wrap.classList.remove('open');
        content.style.maxHeight = 0;
      } else {
        wrappers.forEach((wrap) => {
          const trigger = wrap.querySelector(contentTrigger);
          const content = wrap.querySelector(contentSelector);

          wrap.classList.remove('open');
          trigger.classList.remove('open');
          content.classList.remove('open');
          content.style.maxHeight = 0;
        });
        trigger.classList.add('open');
        content.classList.add('open');
        wrap.classList.add('open');
        content.style.maxHeight = maxHeight;
      }
    });
  });
}

resourcesAccardion(
  '.faq__accardion',
  '.faq__accardion-head',
  '.faq__accardion-content'
);


function jobsFilter() {
    const checkBoxes = document.querySelectorAll('.category-filter input');

  checkBoxes.forEach((checkBtn, i) => {
    checkBtn.addEventListener('click', () => {
      let checkedBtns = []
      checkBoxes.forEach(btn => {
        if (btn.checked) {
          checkedBtns.push(btn)
        }
      })

      if (checkBtn === checkBoxes[0]) {
        checkBoxes.forEach(btn => {
            if (btn !== checkBoxes[0]) {
              btn.checked = false
            }
        })
      }

      if (checkBtn !== checkBoxes[0]) {
        checkBoxes[0].checked = false;
      }

      if (!checkedBtns.length) {
        checkBoxes[0].checked = true;
      }
    })
  })
}

jobsFilter();

// window.galleryFancybox = function galleryFancybox() {
//   console.log(document.querySelector('[data-fancybox="gallery"]'));
//   if(document.querySelector('[data-fancybox="gallery"]')) {
//     Fancybox.fromNodes(
//       Array.from(document.querySelectorAll('[data-fancybox="gallery"]')),
//       {
//         // Your custom options
//       }
//     );
//   }
// }
//
// galleryFancybox();
//
// console.log(1321);



