window.lineTitlesFunc = function lineTitlesFunc(titlesArr) {
  let maxHeight = 0;

  titlesArr.forEach((title) => {
    const height = title.offsetHeight;
    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  titlesArr.forEach((title) => {
    title.style.minHeight = maxHeight + 'px';
  });
};

if(document.querySelector('.looking__slider')) {
  const lookingSlidersArr = document.querySelectorAll('.looking__slider');
  lookingSlidersArr.forEach((lookingSlider) => {
    const lookingTitlesArr = lookingSlider.querySelectorAll('.looking__slider .looking__name');
    setTimeout(function () {
      lineTitlesFunc(lookingTitlesArr);
    }, 300)
  })
}

if(document.querySelector('.looking__card-box')) {
  const lookingCardBoxArr = document.querySelectorAll('.looking__card-box');
  lookingCardBoxArr.forEach((lookingCardBox) => {
    const lookingTitlesArr = lookingCardBox.querySelectorAll('.looking__name');
    setTimeout(function () {
      lineTitlesFunc(lookingTitlesArr);
    }, 300)
  })
}

window.lookingNameSlashFunc = function lookingNameSlashFunc() {
  const lookingNames = document.querySelectorAll(".looking__name");

  if (lookingNames.length) {
    lookingNames.forEach(element => {
      if (!element.classList.contains('is-slash-init')) {
        element.classList.add('is-slash-init');

        let content = element.innerHTML;

        if (content.includes("/")) {
          element.innerHTML = content.replace(/\//g, "/&#8203;");
        }
      }
    });
  }
}

lookingNameSlashFunc();
