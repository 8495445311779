import daterangepicker from 'daterangepicker';
import moment from 'moment';

window.initDatePicker = function initDatePicker() {
    $(".js-date").each(function () {
        $(this).daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            parentEl: $(this).parent(),
            drops: "up",
            minYear: parseInt(moment().format("YYYY"), 10),
            maxYear: parseInt(moment().format("YYYY"), 10)  + 10,
            autoUpdateInput: false,
            opens: "left",
        });
        $(this).on("apply.daterangepicker", function (ev, picker) {
            $(this).val(picker.startDate.format("DD/MM/YYYY"));
        });

        $(this).on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
        });
    });
}

initDatePicker()