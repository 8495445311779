if (document.querySelector('.map')) {
  const mapSlides = [...document.querySelectorAll('.map__country-box')];
  let slidesCountryArr = [];
  let timer;

  if (mapSlides.length) {
    mapSlides.forEach((slide) => {
      let dataSlider = slide.getAttribute('data-country');
      slidesCountryArr = [...slidesCountryArr, dataSlider];
    });
  }

  /*const config = {
    mapPath: site_dir + '/src/js/json/map.geo.json',
    zoomedCountries: countryList,
    width: 1500,
    height: 1500,
    zoomEnable: false,
    countryFillColor: {
      rotate: 65,
      startColor: '#061654',
      endColor: '#04143A',
    },
    accentFillColor: '#E4B700',
    countryStrokeWidth: '0.5px',
    on: {
      countryClick: ({ target, metaMap }) => {
        let id = target.id;

        mapSlides.forEach((slide, i) => {
          if (slide.getAttribute('data-country') === id) {
            slide.click();
          }
        });
      },
      countryMouseLeave: ({ target, metaMap }) => {
        map.unselectAllCountries();

        mapSlides.forEach((slide, i) => {
          if (slide.classList.contains('active')) {
            slide.classList.remove('active');
          }
        });
      },
      countryMouseEnter: ({ target, metaMap }) => {
        let id = target.id;

        if (!slidesCountryArr.includes(id)) {
          target.classList.add('empty');
        }

        if (
          slidesCountryArr.includes(id) &&
          !target.classList.contains('empty')
        ) {
          map.unselectAllCountries();
          metaMap.selectCountry(id);

          mapSlides.forEach((slide, i) => {
            if (slide.classList.contains('active')) {
              slide.classList.remove('active');
            }
            if (slide.getAttribute('data-country') === id) {
              slide.classList.add('active');
            }
          });
        }
      },
    },
  };

  const map = new MetaMap('#map', config);*/
  if (mapSlides.length) {

    mapSlides.forEach((slide, idx) => {
      slide.addEventListener('mouseenter', () => {
        mapSlides.forEach((slide) => {
          slide.classList.remove('active');
        });
        slidesCountryArr.forEach(id => {
          const element = document.getElementById(id);
          if (element) {
            element.classList.remove('active');
          }
        });

        const elPath = document.getElementById(slidesCountryArr[idx]);
        if (elPath) elPath.classList.add('active');
        slide.classList.add('active');
      });

      slide.addEventListener('mouseleave', () => {
        const elPath = document.getElementById(slidesCountryArr[idx]);
        if (elPath) elPath.classList.remove('active');
        slide.classList.remove('active');
      });
    });

    slidesCountryArr.forEach((id, idx) => {
      const element = document.getElementById(id);
      if (element) {
        mapSlides.forEach((slide) => {
          slide.classList.remove('active');
        });
        slidesCountryArr.forEach(idEl => {
          const elementEl = document.getElementById(idEl);
          if (elementEl && elementEl.classList.contains('active')) {
            elementEl.classList.remove('active');
          }
        });

        element.addEventListener("mouseenter", () => {
          mapSlides[idx].classList.add('active');
          const elPath = document.getElementById(slidesCountryArr[idx]);
          if (elPath) elPath.classList.add('active');
        });

        element.addEventListener("mouseleave", () => {
          mapSlides[idx].classList.remove('active');
          const elPath = document.getElementById(slidesCountryArr[idx]);
          if (elPath) elPath.classList.remove('active');
        });

        element.addEventListener('click', () => {
          const elLink = mapSlides[idx];
          const href = elLink.getAttribute('href');
          window.location.href = href;
        });
      }
    });
  }
}
